import { Select, Radio } from "antd"
import { useIntl, FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import { required } from "src/forms/validators"
import { SelectField, TextAreaField } from "src/inputs/connected-input-fields"
import FormInput from "src/inputs/form-fields/FormInput"
import { REASON_FOR_CHANGE_OPTIONS, CHANGED_DOCUMENT_OPTIONS } from "src/globals"
import FormRadioGroup from "src/inputs/form-fields/FormRadioGroup"

const { Option } = Select

type ChangeLogFieldsProps = {
  isMajorUpdate?: boolean,
  docDisabled?: boolean
}

export default function ChangeLogFields({ isMajorUpdate = false, docDisabled=false }: ChangeLogFieldsProps) {
  const intl = useIntl()

  const reasons = Object.values(REASON_FOR_CHANGE_OPTIONS).map((reason, index) => ({
    ...reason,
    value: index + 1,
  }))
  
  const changes = Object.values(CHANGED_DOCUMENT_OPTIONS).map((change, index) => ({
    ...change,
    value: index + 1,
  }))

  return (
  <>
    <Field
        name="changeTo"
        className="radio-group-document"
        component={FormRadioGroup}
        validate={[required]}
        disabled={docDisabled}
        defaultValue="document"
        button
        label={<FormattedMessage id="form.notification.changeTo" /> }>
        {changes.map((change, _index) => (
          <Radio key={change.radiovalue} value={change.radiovalue}>
            <FormattedMessage id={change.label} />
            <p className="details">
              {
                change.inchangelog ? 
                  <FormattedMessage id="form.notification.changeTo.inChangeLog" /> 
                  : change.innotifications ? 
                    <FormattedMessage id="form.notification.changeTo.notInChangeLog" /> 
                    : <FormattedMessage id="form.notification.changeTo.notInChangeLogAndNotifications" />
              }
            </p>
          </Radio>
        ))}
        </Field>
        <p className="radio-group-document-warning">
        <FormattedMessage 
             id="form.notification.changeTo.description"
             values={{
              strong: (chunk) => <strong>{chunk}</strong>,
            }}
            />   
        </p>

    <div className="publish-version-form__details fade-in">
      
      <Field
        name="reasonForChange"
        className="publish-version-form__input"
        component={SelectField}
        label={`${intl.formatMessage({ id: "draft.documents.minormajor.reasonForChange" })}${
          isMajorUpdate ? " *" : ""
        }`}
        placeholder={intl.formatMessage({
          id: "draft.documents.minormajor.reasonForChange.placeHolder",
        })}
        validate={isMajorUpdate ? [required] : []}
      >
        {reasons.map((reason, _index) => (
          <Option key={reason.value} value={reason.value}>
            <FormattedMessage id={reason.label} />
          </Option>
        ))}
      </Field>

      <Field
        name="changeLog"
        className="publish-version-form__input"
        component={FormInput}
        label={`${intl.formatMessage({ id: "draft.documents.minormajor.changeLog" })} *`}
        placeholder={intl.formatMessage({ id: "draft.documents.minormajor.changeLog.placeholder" })}
        validate={[required]}
      />
      <Field
        name="howTo"
        className="publish-version-form__input"
        component={FormInput}
        label={`${intl.formatMessage({ id: "draft.documents.minormajor.howTo" })}`}
        placeholder={intl.formatMessage({ id: "draft.documents.minormajor.howTo.placeholder" })}
        validate={[]}
      />
      <Field
        name="text"
        className="publish-version-form__textarea"
        component={TextAreaField}
        label={intl.formatMessage({ id: "draft.documents.minormajor.text" })}
        placeholder={intl.formatMessage({ id: "draft.documents.minormajor.text.placeholder" })}
        validate={[]}
      />
    </div>
    </>
  )
}
