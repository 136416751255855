import { Radio } from "antd"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import { required } from "src/forms/validators"
import FormRadioGroup from "src/inputs/form-fields/FormRadioGroup"

export default function MinorMajorRadioGroup({name, disabled=false}) {
  return (
    <Field
      name={name}
      component={FormRadioGroup}
      validate={[required]}
      button
      label={<FormattedMessage id="form.notification.selectType" />}
      className="radio-group"
      disabled={disabled}
    >
      <Radio value="minor">
        <FormattedMessage id="form.notification.minorChange" />
        <p>
          <FormattedMessage id="form.notification.minorChangeDescription" />
        </p>
      </Radio>
      <Radio value="major">
        <FormattedMessage id="form.notification.majorChange" />
        <p>
          <FormattedMessage id="form.notification.majorChangeDrescription" />
        </p>
      </Radio>
    </Field>
  )
}
